import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Backspace, Refresh } from '@mui/icons-material';
import { Box, Button, IconButton, InputAdornment, Pagination, Stack, TextField, Typography } from '@mui/material';
import { DataGrid, GridToolbar, gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import { format } from 'date-fns';

export const GET_ME = gql`
    query {
        me
    }
`;

export default function LeadGenDataTable({ type, id }) {
    const [search, setSearch] = React.useState('');
    // const { data, loading, error } = useQuery(GET_ME);
    // console.log('dataTableId', id, type);
    const isAdmin = type === 'admin';
    // console.log(isAdmin);
    // if (loading) return 'Please wait...';
    return (
        <Stack spacing={2} direction={'column'} marginY={3}>
            {/* <Typography variant="h2" textAlign={'center'} gutterBottom>
                {isAdmin ? 'Scanned Lead Data' : ''}
            </Typography> */}
            {/* {error ? (
                <Box sx={{ width: '100%', minHeight: '55vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h2" color="darkred">
                        We have encountered a glitch. We kindly request you to try again later.
                    </Typography>
                </Box>
            ) : ( */}
            <Stack spacing={2}>
                <SearchBox onSearch={setSearch} />

                <ApplicantsTable search={search} newRole={'all'} isAdmin={isAdmin} id={id} />
            </Stack>
            {/* )} */}
            <Box p={6} />
        </Stack>
    );
}

function SearchBox({ onSearch }) {
    const [search, setSearch] = React.useState('');
    React.useEffect(() => {
        const t = setTimeout(() => onSearch(search), 500);
        return () => clearTimeout(t);
    }, [search]);
    return (
        <TextField
            sx={{ maxWidth: 500 }}
            label="Search here..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                    <InputAdornment position="end">
                        {search && (
                            <IconButton onClick={() => setSearch('')}>
                                <Backspace />
                            </IconButton>
                        )}
                    </InputAdornment>
                )
            }}
        />
    );
}

function CustomFooter({ refetch }) {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Box
            sx={{
                width: '100%',
                p: 1,
                display: 'flex',
                justifyContent: 'space-between'
            }}
        >
            <Button onClick={() => refetch()} color="info" variant="outlined" startIcon={<Refresh size="large" />}>
                Refresh
            </Button>

            <Pagination color="primary" count={pageCount} page={page + 1} onChange={(event, value) => apiRef.current.setPage(value - 1)} />
        </Box>
    );
}

function ApplicantsTable({ search, newRole, isAdmin, id }) {
    const limit = 100;
    // console.log(id);
    const { data, error, refetch, loading } = useQuery(GET_DATA, {
        variables: {
            conditions: { limit, text: search },
            role: newRole,
            id
        }
    });
    // console.log(data, '>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<<');
    let dynamicColumns = [];
    if (data?.standApplicants?.length) {
        const firstItem = data.standApplicants[0];
        const keys = Object.keys(JSON.parse(firstItem.data));
        // console.log(firstItem);

        dynamicColumns = keys.map((k) => ({
            field: k,
            headerName: k
                .replace(/_/g, ' ')
                .replace(/([A-Z])/g, ' $1')
                .trim()
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' '),
            width: 150
        }));

        // Add id and role to the beginning of the dynamic columns
        dynamicColumns.unshift(
            {
                field: 'serialNo',
                headerName: 'S.No',
                width: 50
            },
            {
                field: 'regNo',
                headerName: 'Registration ID',
                width: 150
            },
            {
                field: 'name',
                headerName: 'Lead Scanned By',
                width: 150
            },
            {
                field: 'role',
                headerName: 'Role',
                width: 150
            }
        );

        // Add type and remark to the end of the dynamic columns
        dynamicColumns.push(
            {
                field: 'type',
                headerName: 'Lead Type',
                width: 150
            },
            {
                field: 'remark',
                headerName: 'Meeting Summary',
                width: 400
            }
        );
    }

    const columns = [...dynamicColumns];

    const newComponents = isAdmin ? { Toolbar: GridToolbar, Footer: CustomFooter } : { Footer: CustomFooter };
    return (
        <Box>
            {error && <Typography color="error">An error occurred: {error.message}</Typography>}
            <DataGrid
                loading={loading}
                sx={{ height: 550 }}
                sortModel={[{ field: 'created', sort: 'desc' }]}
                components={newComponents}
                componentsProps={{ footer: { refetch } }}
                columns={columns}
                rows={
                    data?.standApplicants?.map((v, index) => ({
                        serialNo: index + 1,
                        id: `${v.id}-${index}`,
                        regNo: v.id,
                        created: v.created,
                        role: v.role,
                        name: v.name,
                        type: v.type,
                        remark: v.remark,
                        ...JSON.parse(v.data)
                    })) || []
                }
                paginationMode="server"
                pageSize={limit}
                rowsPerPageOptions={[limit]}
                onPageChange={(p) => refetch({ conditions: { offset: p.page * limit, limit }, role: newRole })}
                rowCount={data?.total || 0}
            />
        </Box>
    );
}

const GET_DATA = gql`
    query ($conditions: InputConditions, $role: String!, $id: String) {
        standApplicants(conditions: $conditions, role: $role, id: $id) {
            id
            data
            created
            name
            type
            remark
            role
        }
        total: standApplicantsCount(conditions: $conditions, role: $role, id: $id)
    }
`;
