import React, { useState, useEffect } from 'react';
import { gql, useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { Button, IconButton, Typography, Box, CircularProgress, Tooltip, Paper } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';

const GET_ALLOWED_ROLES = gql`
    query GetAllowedRoles {
        allowedRole
    }
`;

export default function GenerateLink() {
    const {
        data: rolesData,
        loading: rolesLoading,
        error: rolesError
    } = useQuery(GET_ALLOWED_ROLES, {
        onError: (e) => console.log(JSON.stringify(e, null, 2))
    });

    const roles = rolesData?.allowedRole?.split(',') ?? [];

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            p={4}
            m={4}
            border={1}
            borderRadius={4}
            borderColor="grey.300"
            bgcolor="background.paper"
        >
            <Typography variant="h2" gutterBottom>
                Generate Registration Links
            </Typography>
            <Typography variant="body1" align="center" paragraph>
                You can generate and manage links for each role.
            </Typography>
            {rolesLoading ? (
                <CircularProgress />
            ) : rolesError ? (
                <Typography color="error">Error loading roles</Typography>
            ) : roles.length === 0 ? (
                <Typography>No roles available.</Typography>
            ) : (
                roles.map((role) => <RoleLink key={role} role={role} />)
            )}
        </Box>
    );
}

const GENERATE_LINK_MUTATION = gql`
    mutation GenerateLink($role: String!) {
        generateLink(role: $role) {
            url
            role
        }
    }
`;

const DELETE_LINK_MUTATION = gql`
    mutation DeleteLink($role: String!) {
        deleteLink(role: $role)
    }
`;

const GET_LINK_QUERY = gql`
    query GetLink($role: String!) {
        link(role: $role) {
            url
            role
        }
    }
`;

function RoleLink({ role }) {
    const [link, setLink] = useState(null);
    const [loading, setLoading] = useState(false);
    const [copied, setCopied] = useState(false);
    const [showFullLink, setShowFullLink] = useState(false);

    const [getLink, { refetch }] = useLazyQuery(GET_LINK_QUERY, {
        variables: { role },
        onCompleted: (data) => {
            if (data?.link) {
                setLink(data.link.url);
            }
        },
        onError: (e) => console.log(JSON.stringify(e, null, 2))
    });

    useEffect(() => {
        getLink();
    }, [role]);

    const [generateLink] = useMutation(GENERATE_LINK_MUTATION, {
        onCompleted: (data) => {
            setLink(data.generateLink.url);
            setLoading(false);
        },
        onError: () => {
            setLoading(false);
        }
    });

    const [deleteLink] = useMutation(DELETE_LINK_MUTATION, {
        onCompleted: () => {
            setLink(null);
            setLoading(false);
        },
        onError: () => {
            setLoading(false);
        }
    });

    const handleGenerateLink = async () => {
        setLoading(true);
        await generateLink({ variables: { role } });
    };

    const handleCopyLink = () => {
        if (link) {
            navigator.clipboard.writeText(link);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        }
    };

    const handleDeleteLink = async () => {
        setLoading(true);
        await deleteLink({ variables: { role } });
    };

    const handleViewLink = () => {
        setShowFullLink(!showFullLink);
    };

    return (
        <Paper key={role} elevation={3} sx={{ width: '100%', p: 2, mb: 2 }}>
            <Box display="flex" flexDirection="row" alignItems="center">
                <Typography variant="h6" sx={{ flex: 0.5 }}>
                    {role.charAt(0).toUpperCase() + role.slice(1)}
                </Typography>
                {link ? (
                    <>
                        <Typography variant="body1" sx={{ mr: 2, flex: 1 }}>
                            {showFullLink ? link : `${link.slice(0, 50)}...`}
                        </Typography>
                        <Tooltip title="Copy Link">
                            <IconButton color={copied ? 'success' : 'default'} onClick={handleCopyLink} disabled={loading}>
                                {copied ? <CheckIcon /> : <ContentCopyIcon />}
                            </IconButton>
                        </Tooltip>
                        {/* <Tooltip title="View Full Link">
                            <IconButton onClick={handleViewLink} disabled={loading}>
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip> */}
                        <Tooltip title="Delete Link">
                            <IconButton color="error" onClick={handleDeleteLink} disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : <DeleteIcon />}
                            </IconButton>
                        </Tooltip>
                    </>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleGenerateLink}
                        disabled={loading}
                        startIcon={loading ? <CircularProgress size={24} /> : null}
                    >
                        {loading ? 'Generating...' : 'Generate Link'}
                    </Button>
                )}
            </Box>
        </Paper>
    );
}
