import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { API_URL, AUTH_TOKEN } from './Constants';
import { setContext } from '@apollo/client/link/context';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

//old reception

const authLink = setContext((_, { headers }) => {
    // const token = localStorage.getItem(AUTH_TOKEN);
    const token = sessionStorage.getItem(AUTH_TOKEN);

    if (!token) {
        console.warn('Authorization token is missing!'); // or throw new Error('Authorization token is missing!');
    }

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : ''
        }
    };
});

const httpLink = createUploadLink({
    uri: API_URL,
    headers: {
        'Apollo-Require-Preflight': 'true'
    }
});
const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

// ==============================|| APP ||============================== //
client.resetStore();
const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <ApolloProvider client={client}>
                        <Routes />
                    </ApolloProvider>
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
